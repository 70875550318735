import React, { useState } from "react";
import AppModal from "../../modals";
import useTranslation from "../../../utils/hooks/use-translation";
import { InlineShareButtons } from "sharethis-reactjs";
import useAuth from "../../../utils/hooks/use-auth";
// Assuming you're using react-icons for TikTok icon

const ShareBadgePopup = (props) => {
  const { t } = useTranslation();
  const {
    show,
    onClose,
    type,
    shareMeassage = false,
    acheivement = true,
  } = props;
  const shareUrl = "https://www.google1.com";
  const instagramShareUrl = `https://www.instagram.com/accounts/login/?url=${shareUrl}`;
  const tiktokShareUrl = `https://www.tiktok.com/upload?u=${shareUrl}`;
  const { userDetail } = useAuth();
  const badgeImageUrl =
    userDetail &&
    userDetail?.badgeurl &&
    userDetail?.badgeurl &&
    userDetail?.badgeurl?.imageUrl;

  return (
    <>
      <AppModal
        show={show}
        gaClass="modal-social-share"
        onClose={onClose}
        bodyClass="px-5 pt-0 pb-5 modal-filter"
        size="md"
      >
        {acheivement && (
          <p className="modal-description">
            {t.model_messages.share_this_acheivement}
            <img
              src="/assets/img/icons/fan.svg"
              className="badge"
              alt="badge"
            />
          </p>
        )}

        {shareMeassage && (
          <p className="modal-description">{t.common.share_this}</p>
        )}
        <div className="d-flex ">
          <div>
            <a
              href={tiktokShareUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="st-btn st-last bg-transparent mr-2 d-block"
            >
              <img
                src="/assets/img/icons/tiktok-icon.png"
                alt="icon"
                className="bg-transparent"
              />
            </a>
          </div>
          <div>
            <a
              href={instagramShareUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="st-btn st-last bg-transparent mr-2 d-block"
            >
              <img
                src="/assets/img/icons/instagram.png"
                alt="icon"
                className="bg-transparent"
              />
            </a>
          </div>
          <InlineShareButtons
            config={{
              // alignment: "center",
              color: "social",
              enabled: true,
              font_size: 16,
              labels: "cta",
              language: "en",
              networks: [
                // "email",
                "facebook",
                "whatsapp",
                // "linkedin",
                // "messenger",

                "twitter",
              ],
              padding: 12,
              radius: 6,
              show_total: false,
              size: 40,
              url: "https://cokestudio-bangla.coke2home.com/",
              image: badgeImageUrl
                ? badgeImageUrl
                : "https://d35f5vastl4boc.cloudfront.net/image/desktop.jpeg",
              description: "Achievement",
              title: "Achievement",
              message: "My Achievement",
              subject: "Check My Achievement",
            }}
          />
        </div>
      </AppModal>
    </>
  );
};

export default ShareBadgePopup;
