import {useEffect, useRef } from 'react';
import gluedin from 'gluedin';
import './styles/sass/style.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PublicLayout from './components/layouts/Publiclayout';
import { LoaderProvider } from './utils/context';
import { PopupProvider } from './components/common/context/popup-context';
import { Toaster } from 'react-hot-toast';
import { ViewportProvider } from './utils/context/viewport-context';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from './config';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setIpDetails } from './store/slices/common-slice';

function App() {
  const reCaptchaRef = useRef();
  const dispatch = useDispatch();
  let gluedInSDKInitilize = new gluedin.GluedInSDKInitilize();
  gluedInSDKInitilize.initialize({
    //bangla  keys
    apiKey: process.env.REACT_APP_GLUEDIN_API_KEY,
    secretKey: process.env.REACT_APP_GLUEDIN_SECRET_KEY,

    //bharat keys 
    // apiKey: '9c70e3f8-d82c-4b5f-a358-a9e00589be16',
    // secretKey: '4ef7e937-a942-4ece-a925-1542bfa88336',
  });
// fetch ip data 
useEffect(() => {
  const fetchIPAddress = async () => {
    try {
      const ipResponse = await axios.get(process.env.REACT_APP_GET_IP);
      const ipAddress = ipResponse.data.ip;
      fetchData(ipAddress);
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };
  const fetchData = async (ipAddress) => {
    let ipUrl = `${process.env.REACT_APP_IP_DETAIL}${ipAddress}?key=${process.env.REACT_APP_IP_KEY}` // dynamic ip
    try {
      const response = await axios.get(ipUrl);
      dispatch(setIpDetails(response.data))
      localStorage.setItem('ipDetails', JSON.stringify(response.data));
    } catch (error) {
      console.error('Error:', error);
    }
  };
  fetchIPAddress();
}, []);// eslint-disable-line react-hooks/exhaustive-deps
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <ViewportProvider>
        <LoaderProvider>
          <PopupProvider>
            <PublicLayout />
            <Toaster
              position="top-center"
              reverseOrder={false}
              toastOptions={{
                className: '',
                style: {
                  backgroundColor: 'black', // Black background color
                  color: 'white', // White text color
                  border: '1px solid #713200',
                  padding: '10px',
                },
                duration: 3000, // Set a duration greater than 0 to remove the close button
                icon: false, // Remove success icon
              }}
            />
                      <ReCAPTCHA
            theme="dark"
            size="invisible"
            ref={reCaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          />
          </PopupProvider>
        </LoaderProvider>
      </ViewportProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
